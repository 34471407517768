import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

const StoryItem = (Props) => {
  return (
    <>
      <div className="flex flex-row gap-4 items-center h-[80px]">
        <div className="flex relative select-none">
          <div className="p-[5px] rounded-full bg-[black] z-10" />
          {Props.ShowLine !== false && <div className="top-0 left-[4px] absolute h-[80px] bg-[#eeeeee] w-[2px] z-0" />}
        </div>
        <div className="flex text-[26px] font-bold text-[black] font-[SUIT-Regular] w-[70px]">{Props.Year}</div>
        <div className="flex text-[18px] font-bold text-[black] font-[SUIT-Regular] w-[120px]">{Props.Month}.</div>
        <div className="flex text-[18px] text-[black] font-[SUIT-Regular] w-fit">{Props.Contents}</div>
      </div>
    </>
  );
};

export default StoryItem;
